import React from "react";

function PageNotFound() {
  return (
    <div>
      <h1>
        Hmmm, looks like the page your looking for is hiding. Please go back to
        the previous page.
      </h1>
    </div>
  );
}

export default PageNotFound;
